<i18n>
ru:
  pointsTextFrom: 'от '
  pointsTextTo: 'до '
ua:
  pointsTextFrom: 'від '
  pointsTextTo: 'до '
us:
  pointsTextFrom: 'from '
  pointsTextTo: 'to '
</i18n>

<template>
  <div
    class="v-pb-xs"
    v-html="content.split('{{pointsPage_ranks}}')[0]"
  />
  <div class="v-d-flex v-flex-row-no-wrap v-justify-content-center v-scrollbar">
    <div
      v-for="(rank, index) in ranks"
      :key="rank.ID"
    >
      <div
        class="v-points-rank v-container v-overflow-hidden v-d-flex v-flex-row-no-wrap v-align-items-center"
      >
        <div class="v-px-lg">
          <div class="v-mb-md">
            <common-progress-bar-radial
              :circle-size="index < ranks.length - 1 ? 5 : 40"
              :milestones="index < ranks.length - 1 ? [0, 100] : [100]"
              :size="isSmall ? 25 : 40"
              :value="(index + 1) * (100 / ranks.length)"
            >
              <div class="v-d-flex v-w-100 v-h-100 v-align-items-center v-justify-content-center">
                <span
                  :class="
                    index < ranks.length - 1
                      ? 'v-points-rank--title'
                      : 'v-points-rank--title v-points-rank--title__filled'
                  "
                  v-html="sanitize(rank.Title)"
                />
              </div>
            </common-progress-bar-radial>
          </div>
          <div class="v-d-flex v-body-text-color-light v-font-weight-500">
            <span v-html="translate('points.pointsTextFrom')" />
            <common-currency
              class="v-user-ranks-benchmark v-align-currency-center"
              :amount="rank.Benchmark"
            />
          </div>
          <div
            v-if="index < ranks.length - 1"
            class="v-d-flex v-body-text-color-light v-font-weight-500"
          >
            <span v-html="translate('points.pointsTextTo')" />
            <common-currency
              class="v-user-ranks-benchmark v-align-currency-center"
              :amount="ranks[index + 1].Benchmark - 1"
            />
          </div>
        </div>
        <hr
          v-if="index < ranks.length - 1"
          class="v-points-rank--line"
        />
      </div>
    </div>
  </div>
  <div
    v-if="content.includes('{{pointsPage_ranks}}')"
    class="v-pb-xs"
    v-html="content.split('{{pointsPage_ranks}}')[1]"
  />
</template>

<script setup lang="ts">
import type { Rank } from '~types/accountStore'

import { useWindowSize } from '@arora/common'

const { content } = defineProps<{
  title: string
  content: string
}>()

const accountStore = useAccountStore()
const { isSmall } = useWindowSize()

const ranks = computed<Rank[]>(() => accountStore.RanksStat.data?.Ranks ?? [])

const { translate, sanitize } = useI18nSanitized()
onMounted(() => {
  accountStore.initRanks()
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-points-rank {
  font-size: 1.125rem;

  &--title {
    font-weight: 600;

    &__filled {
      color: variables.$PrimaryColor;
    }
  }

  &--line {
    width: 175px;
    height: 2px;
    color: variables.$PrimaryBackgroundColor;
    opacity: 0.8;
  }
}
</style>
