<template>
  <div v-if="contentArray.length > 1">
    <div
      class="v-pb-xs"
      v-html="contentArray[0]"
    />
    <common-payment-cards :is-footer="false" />
    <div
      class="v-pb-xs v-mt-sm"
      v-html="contentArray[1]"
    />
  </div>
  <div
    v-else
    class="v-pb-xs"
    v-html="contentArray[0]"
  />
  <div class="v-mb-sm">
    <div
      class="v-row v-requisites__tables"
      :class="{ 'v-justify-content-center': requisites.length > 1 }"
    >
      <div
        v-for="(requisite, indexRequisite) in requisites"
        :key="`payment-rules-requisites-${indexRequisite}`"
        class="v-col-lg-4 v-col-sm-6 v-col-12 v-mb-sm"
      >
        <div class="v-requisites__table">
          <div
            v-for="(item, index) in requisite"
            :key="`payment-rules-requisite-${index}`"
          >
            <div
              v-if="patternShortName.test(item.title)"
              class="v-requisites__stroke"
            >
              <h3
                class="v-requisites__stroke__content v-text-center v-requisites__stroke__content-short-title v-font-weight-600"
                v-html="item.content"
              />
            </div>
            <div
              v-else-if="patternFullName.test(item.title)"
              class="v-requisites__stroke"
            >
              <p
                class="v-requisites__stroke__content v-text-center"
                v-html="item.content"
              />
            </div>
            <div
              v-else
              class="v-requisites__stroke"
            >
              <p
                class="v-requisites__stroke__title"
                v-html="item.title"
              />
              <p
                class="v-requisites__stroke__content"
                v-html="item.content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LegalInfoRequisite } from '~types/preloadRequests'

const { content, requisites } = defineProps<{
  title: string
  content: string
  requisites: LegalInfoRequisite[]
}>()

const contentArray = computed<string[]>(() =>
  content.split('<p><code>{{paymentRules_paymentSystems}}</code></p>')
)
const patternShortName = /^#{2}[^#].*$/
const patternFullName = /^#{3}.*/
</script>

<style lang="scss">
@use '~/assets/variables';

.v-requisites {
  &__table {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.1rem;
    padding: 1.7rem;
    box-shadow: variables.$CardShadow;
    border-radius: variables.$BorderRadius;
  }
  &__stroke {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    font-size: 1.1rem;
    &__title {
      font-weight: 600;
    }
    &__title,
    &__content {
      margin: 0;
      &-short-title {
        text-transform: uppercase;
      }
    }
  }
}
</style>
