<i18n>
ru:
  offer: Публичная оферта
  paymentRules: Условия оплаты
  personalData: Политика обработки персональных данных
  pointsPage: Условия бонусной системы
ua:
  offer: Публічна оферта
  paymentRules: Умови оплати
  personalData: Політика обробки персональних даних
  pointsPage: Умови бонусної системи
us:
  offer: Public offer
  paymentRules: Payment terms
  personalData: Personal data processing policy
  pointsPage: Bonus system terms and conditions
</i18n>

<template>
  <div class="v-legal-page">
    <ClientOnly>
      <div class="v-mb-sm">
        <arora-chips
          with-links
          :chips="tabSettingsArray"
          v-model:selected="tabSelectedId"
        />
      </div>
    </ClientOnly>
    <page-legal-offer
      v-if="tabSelectedId === 0"
      :content="appConfig.StaticTexts.offer.content ?? ''"
      :title="appConfig.StaticTexts.offer.title ?? ''"
    />
    <page-legal-payment-rules
      v-else-if="tabSelectedId === 1"
      :content="appConfig.StaticTexts.paymentRules.content ?? ''"
      :requisites="appConfig.StaticTexts.requisites ?? ([] as LegalInfoRequisite[])"
      :title="appConfig.StaticTexts.paymentRules.title ?? ''"
    />
    <page-legal-personal-data
      v-else-if="tabSelectedId === 2"
      :content="appConfig.StaticTexts.personalData.content ?? ''"
      :title="appConfig.StaticTexts.personalData.title ?? ''"
    />
    <page-legal-points
      v-else-if="tabSelectedId === 3"
      :content="appConfig.StaticTexts.pointsPage.content ?? ''"
      :title="appConfig.StaticTexts.pointsPage.title ?? ''"
    />
    <template
      v-else
      v-for="[index, additionalDocument] in appConfig.StaticTexts.additional.entries()"
    >
      <div
        v-if="tabSelectedId === index + 3"
        :key="index"
        class="v-pb-xs"
        v-html="additionalDocument.content"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TabsSettings } from '~types/common'
import type { LegalInfoRequisite } from '~types/preloadRequests'

const { activeTab } = defineProps<{
  activeTab: number
}>()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const tabSelectedId = ref<number | undefined>(undefined)
const tabSettingsArray = ref<TabsSettings<number>[]>([] as TabsSettings<number>[])
tabSelectedId.value = activeTab

onMounted(async () => {
  if (appConfig.StaticTexts.offer.visibility)
    tabSettingsArray.value.push({
      ID: 0,
      Name: appConfig.StaticTexts.offer.title ?? translate('legalPage.offer'),
      Link: appConfig.VueSettingsPreRun.Links.AgreementLink
    })

  if (appConfig.StaticTexts.paymentRules.visibility)
    tabSettingsArray.value.push({
      ID: 1,
      Name: appConfig.StaticTexts.paymentRules.title ?? translate('legalPage.paymentRules'),
      Link: appConfig.VueSettingsPreRun.Links.PayRulesLink
    })

  if (appConfig.StaticTexts.personalData.visibility)
    tabSettingsArray.value.push({
      ID: 2,
      Name: appConfig.StaticTexts.personalData.title ?? translate('legalPage.personalData'),
      Link: appConfig.VueSettingsPreRun.Links.PersonalDataLink
    })

  if (appConfig.StaticTexts.pointsPage.visibility)
    tabSettingsArray.value.push({
      ID: 3,
      Name: appConfig.StaticTexts.pointsPage.title ?? translate('legalPage.pointsPage'),
      Link: appConfig.VueSettingsPreRun.Links.PointsLink
    })

  if (appConfig.StaticTexts.additional && appConfig.StaticTexts.additional.length > 0) {
    for (const item of appConfig.StaticTexts.additional) {
      tabSettingsArray.value.push({
        ID: tabSettingsArray.value.length,
        Name: item.title,
        Link: item.link
      })
    }
  }
})
</script>

<style lang="scss">
.v-legal-page {
  h1 {
    font-size: 1.6rem;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.3rem;
  }

  ul {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }

  ol {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }
}
</style>
